/* eslint-disable linebreak-style */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from "react-tabs";
import Button from "elements/Button";

export default function AllPortfolio({ data }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const categories = [
    { name: "E-Commerce", data: data.filter((item) => item.type === "E-Commerce") },
    { name: "Real Estate", data: data.filter((item) => item.type === "Real Estate") },
    { name: "Fashion", data: data.filter((item) => item.type === "Fashion") },
    { name: "Food & Restaurant", data: data.filter((item) => item.type === "Restraunt") },
    { name: "Portfolios", data: data.filter((item) => item.type === "Portfolios") },
    { name: "Jewelleries", data: data.filter((item) => item.type === "Jewellery") },
    { name: "Sales", data: data.filter((item) => item.type === "sales") },
    { name: "Rentels", data: data.filter((item) => item.type === "rentals") },
    { name: "Social", data: data.filter((item) => item.type === "social") },
    { name: "Travel", data: data.filter((item) => item.type === "Travel") },
    { name: "Furnitures", data: data.filter((item) => item.type === "Furnitures") },
  ];

  return (
    <Fade bottom>
      <section className="container my-5 mx-auto">
        <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
          <TabList
            style={{
              display: "flex",
              overflowX: "auto",
              whiteSpace: "nowrap",
              borderBottom: "1px solid #d1d5db",
              backgroundColor: "#f3f4f6",
              borderRadius: "12px 12px 0 0", // Smooth top-left and top-right corners
              padding: "5px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              gap: "5px", // Adds small gap between tabs
            }}
          >
            {categories.map((tab, index) => (
              <Tab
                key={index}
                style={{
                  padding: "10px 16px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  borderRadius: "8px 8px 0 0",
                  border: selectedIndex === index ? "1px solid #d1d5db" : "none",
                  borderBottom: selectedIndex === index ? "none" : "1px solid #d1d5db",
                  backgroundColor: selectedIndex === index ? "#ffffff" : "#e5e7eb",
                  color: selectedIndex === index ? "#000000" : "#4b5563",
                  boxShadow: selectedIndex === index ? "0px 2px 5px rgba(0, 0, 0, 0.1)" : "none",
                }}
              >
                {tab.name}
              </Tab>
            ))}
          </TabList>

          {categories.map((category, index) => (
            <TabPanel key={index}>
              <div className="grid grid-cols-2 sm:grid-cols-3 sm:gap-2 xl:gap-8 justify-items-center mt-4">
                {category.data.map((item, idx) => (
                  <Fade bottom delay={500 * idx} key={idx}>
                    <Button type="link" href={`/project/${item.id}`}>
                      <div className="group rounded-2xl shadow-xl w-auto m-3 transform transition duration-500 hover:scale-110 portofolio-card">
                        <div className="relative">
                          <img src={item.imageUrl} alt="Portfolio" className="rounded-t-2xl z-0" />
                          <div className="absolute flex w-full h-full top-0 opacity-0 bg-black justify-center rounded-t-2xl rounded-b img-hover">
                            <button className="focus:outline-none">
                              <svg className="w-20 h-20 text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="py-4">
                          <h2 className="text-theme-blue text-center text-xl">{item.title}</h2>
                          <p className="font-light text-gray-400 text-center">{item.type}</p>
                        </div>
                      </div>
                    </Button>
                  </Fade>
                ))}
              </div>
            </TabPanel>
          ))}
        </Tabs>
      </section>
    </Fade>
  );
}
