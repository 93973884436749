/* eslint-disable linebreak-style */
/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import Button from "elements/Button";
import React, { useState } from "react";

export const DiscussForm = (actions) => {
  const { data } = actions;

  const [selectedOptions, setSelectedOptions] = useState({
    webdevelopment: false,
    uxDesign: false,
    Appdevelopment: false,
    digitalmarketing: false,
    branding: false,
    flimmaking: false,
  });

  const handleCheckboxChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    // Collect selected options
    const selectedServices = Object.keys(selectedOptions)
      .filter((key) => selectedOptions[key])
      .map((key) => key.replace(/([A-Z])/g, " $1").toLowerCase())
      .join(", ");

    const message = `Hai! I am.\n
      Name: ${data.name}\n
      Company: ${data.company}\n
      Email: ${data.email}\n
      Phone: ${data.phone}\n
      Interested workings: ${selectedServices || "None"}`;

    const phoneNumber = "9360620595";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message,
    )}`;

    // Open WhatsApp with the message
    window.open(whatsappURL, "_blank");
  };

  return (
    <section className="container mx-auto mt-10 flex flex-col justify-center">
      <div className="font-sans bg-gray-50 flex flex-col lg:flex-row">
        {/* Left Side - Contact Form */}
        <div className="flex flex-col flex-1 p-8 max-w-lg">
          <h1 className="text-4xl font-bold mb-4">Let Discuss</h1>
          <p className="font-light text-xl text-gray-400 leading-relaxed mb-8">
            Please fill out the form below to discuss your project and we wll
            get back to you in less than 24 hours.
          </p>
          <form className="space-y-4">
            <div className="flex space-x-4">
              <input
                id="name"
                name="name"
                type="text"
                value={data.name}
                placeholder="Your name"
                onChange={actions.onChange}
                className="w-full p-3 border border-gray-300 rounded"
              />
              <input
                id="company"
                name="company"
                type="text"
                value={data.company}
                placeholder="Your company"
                onChange={actions.onChange}
                className="w-full p-3 border border-gray-300 rounded"
              />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="Your email address"
              onChange={actions.onChange}
              className="w-full p-3 border border-gray-300 rounded"
            />
            <input
              id="phone"
              name="phone"
              type="number"
              value={data.phone}
              placeholder="Your contact number"
              onChange={actions.onChange}
              className="w-full p-3 border border-gray-300 rounded"
            />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {/* Checkbox options */}
              <label
                htmlFor="website-design"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="website-design"
                  name="webdevelopment"
                  checked={selectedOptions.webdevelopment}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>WEB DEVELOPMENT</span>
              </label>
              <label
                htmlFor="ux-design"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="ux-design"
                  name="uxDesign"
                  checked={selectedOptions.uxDesign}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>UI/UX design</span>
              </label>
              <label
                htmlFor="user-research"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="user-research"
                  name="Appdevelopment"
                  checked={selectedOptions.Appdevelopment}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>APP DEVELOPMENT</span>
              </label>
              <label
                htmlFor="content-creation"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="content-creation"
                  name="digitalmarketing"
                  checked={selectedOptions.digitalmarketing}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>DIGITAL MARKETING</span>
              </label>
              <label
                htmlFor="strategy-consulting"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="strategy-consulting"
                  name="branding"
                  checked={selectedOptions.branding}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>BRANDING</span>
              </label>
              <label
                htmlFor="flimmaking"
                className="flex items-center space-x-2"
              >
                <input
                  type="checkbox"
                  id="flimmaking"
                  name="flimmaking"
                  checked={selectedOptions.flimmaking}
                  onChange={handleCheckboxChange}
                  className="text-purple-600"
                />
                <span>CORPRATE AD FLIM MAKING</span>
              </label>
            </div>
            <Button
              type="submit"
              className="w-full py-3 bg-theme-purple text-white rounded mt-4 border-theme-purple hover:bg-dark-theme-purple transition duration-200 focus:outline-none"
              onClick={handleSubmit}
            >
              Send message
            </Button>
          </form>
        </div>

        {/* Right Side - Map */}
        {/* <div className="flex-1 h-auto w-full lg:h-screen lg:w-auto">
          <iframe
            src="https://www.google.com/maps?q=F3,1/113,+Jmj+Complex+Proglobal+Solution+TNAU+Nagar+Othakadai+Madurai+-+625107,+Tamil+Nadu&z=14&output=embed"
            width="100%"
            height="100%"
            title="Google Map"
            className="border-none"
            allowFullScreen
          />
        </div> */}
        <div className="flex-1 h-10 w-full lg:w-auto md:m-8 lg:m-10 xl:m-10">
          <iframe
            src="https://www.google.com/maps?q=F3,1/113,+Jmj+Complex+Proglobal+Solution+TNAU+Nagar+Othakadai+Madurai+-+625107,+Tamil+Nadu&z=14&output=embed"
            style={{
              border: 0,
              height: 600,
            }}
            allowFullScreen=""
            loading="lazy"
            title="Pro Global Solutions Location"
            className="rounded-lg shadow-md w-full h-72 md:h-96 lg:w-full lg:h-full"
          />
        </div>
      </div>
    </section>
  );
};
