/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */

import Web from "../assets/images/Services/Web.png";
import outdooradvertisement from "../assets/images/Services/outdooradvertisement.png";
import Mobile from "../assets/images/Services/Mobile.png";
import UIUX from "../assets/images/Services/Design.png";
import Brand from "../assets/images/Services/Brand.png";
import digitalmarketing from "../assets/images/Services/Digitalmarketing.png";

import legalrightsconsumerprotectionassociation from "../assets/images/Portfolio/Images/Social/legalrightsconsumerprotectionassociation.jpg";

// Portfolio Img Imports
import kadai from "../assets/images/Portfolio/Images/ecommerce/Maduraikadai.jpg";
import fooddeliverypos from "../assets/images/Portfolio/foodelivery.png";
import milletnoodles from "../assets/images/Portfolio/Images/Restraunt/milletnoodles.png";

import carrental from "../assets/images/Portfolio/Images/Rentals/carrental.jpg";
import indusgo from "../assets/images/Portfolio/Images/Rentals/Car.png";

import pgsapp from "../assets/images/Portfolio/pgsapp.jpg";
import landcube from "../assets/images/Portfolio/Images/Realestate/landcube.jpg";
import homezy from "../assets/images/Portfolio/Images/Realestate/Homezy.jpg";
import homeland from "../assets/images/Portfolio/Images/Realestate/Homeland.jpg";

import Arasan from "../assets/images/Portfolio/Images/Sales/Arasan.jpg";
import expensify from "../assets/images/Portfolio/Images/ecommerce/Expensify.jpg";
import grocergo from "../assets/images/Portfolio/Images/ecommerce/Grocergo.jpg";
import Stream from "../assets/images/Portfolio/Images/Sales/Balajigasapp.jpg";
import Freelance from "../assets/images/Portfolio/smjewellers.jpg";
import balajiweb from "../assets/images/Portfolio/Images/Portfolios/balajiweb.jpg";
import Jupiter from "../assets/images/Portfolio/Images/ecommerce/Jupiter.jpg";
import wealthwiseweb3 from "../assets/images/Portfolio/wealthwiseweb3.jpg";
import coffee from "../assets/images/Portfolio/Images/Restraunt/coffee.png";
import ManagementApp from "../assets/images/Portfolio/ManagementApp.png";
import posrestraunt from "../assets/images/Portfolio/POSrestarunt.jpg";
// import crm from "../assets/images/Portfolio/CRM.jpg";
import jupiterdigitals from "../assets/images/Portfolio/Images/Portfolios/Jupiterdigitals.jpg";
import tamarindtree from "../assets/images/Portfolio/Images/Portfolios/Tree.png";
import cbetravel from "../assets/images/Portfolio/Images/Rentals/Travel.png";

// Advantages
import Communicative from "../assets/images/Advantages/Communicative.png";
import Collaborative from "../assets/images/Advantages/Collaborative.png";
import Management from "../assets/images/Advantages/Management.png";
import Favorite from "../assets/images/Advantages/Favorite.png";
import pgsstudio from "../assets/images/Portfolio/Images/fashion/pgsstudio.png";
import Defashion from "../assets/images/Portfolio/Images/fashion/Defashion.jpg";

// import aariya from "../assets/images/TeamMembers/aariya new logo.png";
import smjewelleries from "../assets/images/TeamMembers/SMJewelleries.png";
import ashvattha from "../assets/images/TeamMembers/Ashvattha Logo.png";
import ccellpark from "../assets/images/TeamMembers/Cell Park Logo.png";
import Dreamz from "../assets/images/TeamMembers/Dreamz logo (2).png";
import dharma from "../assets/images/TeamMembers/DS Logo.png";
import hyrox from "../assets/images/TeamMembers/Hydrox Logo HD.png";
import Iris from "../assets/images/TeamMembers/Iris Logo.png";
import bissfullmind from "../assets/images/TeamMembers/Blissfullmind.jpg";
import venkedeshwarasupermarket from "../assets/images/TeamMembers/Venkadeshwarasupermarket.jpg";
import arasan from "../assets/images/TeamMembers/Arasan.png";
import thaidepartmentalstore from "../assets/images/TeamMembers/Thaideptstore.jpg";
import wishdomcooltech from "../assets/images/TeamMembers/Wishdomcooltech.jpg";
import Kiara from "../assets/images/TeamMembers/Kiara Logo.png";
import bharathgas from "../assets/images/TeamMembers/Bharathgas.jpeg";
import nestschool from "../assets/images/TeamMembers/Nest Pre School Logo.png";
import ommuruga from "../assets/images/TeamMembers/OmMurugasarees.jpg";
import ramalingatiles from "../assets/images/TeamMembers/Ramalinjgatiles.jpg";
import tirupathijewelleries from "../assets/images/TeamMembers/ThirupathiJewelleries.jpg";
import sivakasiprinters from "../assets/images/TeamMembers/Sivakasiprinters.jpg";
import hna from "../assets/images/TeamMembers/hnamenswear.jpg";
import vikashahospital from "../assets/images/TeamMembers/vikasadentalhospital.jpg";
import kids from "../assets/images/TeamMembers/Kids o Kids Logo.png";
import MaduraiKadai from "../assets/images/TeamMembers/Madurai Kadai logo HD.png";
import mobilebazar from "../assets/images/TeamMembers/Mobile Bazar Logo.png";

export const Services = [
  {
    title: "WEB DEVELOPMENT",
    imageUrl: Web,
    animation: "left",
  },
  {
    title: "APP DEVELOPMENT",
    imageUrl: Mobile,
    animation: "up",
  },
  {
    title: "AI Integration",
    imageUrl: UIUX,
    animation: "right",
  },
  {
    title: "DIGITAL MARKETING",
    imageUrl: digitalmarketing,
    animation: "left",
  },
  {
    title: "BRANDING",
    imageUrl: Brand,
    animation: "up",
  },
  {
    title: "CORPRATE AD FLIM MAKING",
    imageUrl: outdooradvertisement,
    animation: "right",
  },
];

export const Portfolios = [

  {
    id: "restraunt",
    contents:
      "A Restaurant POS (Point of Sale) system is a software solution designed for managing orders, payments, and overall operations within a restaurant. It streamlines workflows by handling order tracking, inventory management, table reservations, and customer transactions in real-time. With features like billing, kitchen display integration, and sales analytics, a Restaurant POS enhances efficiency, reduces errors, and helps improve customer service.",
    title: "RESTAURANT",
    imageUrl: posrestraunt,
    type: "Restraunt",
    responsibility: ["Website"],
  },

  {
    id: "pgsstudio",
    contents:
      "We strive to build on our vision. As a fashion label, we do our best to create amazing experiences for all people. We are always looking to make something that is easy for everyone. We are very dedicated to making our products. We offer unique and creative products to a wide range of people. We have a variety of styles, but for most people, all of the options are in the box. We specialize in making things that make you happy. We are a fashion studio based in California. We create unique designs that will blow your mind. We also design unique jewelry pieces. Fashion is an ART that can not be grasped by everyone.",
    title: "PGS STUDIO",
    imageUrl: pgsstudio,
    type: "Fashion",
    responsibility: ["Website"],
    credit: "https://67b5d8036a31a6c4768e11c7--fashiondesign1.netlify.app/",
  },
  {
    id: "Defashion",
    contents:
      "Discover the Best Fashion Collection The High-Quality Collection Shop Now.",
    title: "De Fashion",
    imageUrl: Defashion,
    type: "Fashion",
    responsibility: ["Website"],
    credit: "https://defashion.vercel.app/",
  },
  // Real estate
  {
    id: "landqube",
    title: "LAND CUBE",
    imageUrl: landcube,
    contents:
      "LandCube is a comprehensive real estate website designed to streamline property searches and connect buyers, sellers, and renters. The platform offers a user-friendly experience with advanced search filters, allowing users to explore listings based on location, property type, price, and amenities. LandCube provides detailed property descriptions, high-quality images, virtual tours, and interactive maps to help users make informed decisions. With features like real-time availability updates, neighborhood insights, and expert guidance, LandCube simplifies the property discovery process, making it easy for users to find their ideal home or investment property.",
    type: "Real Estate",
    responsibility: ["Website"],
    credit: "https://landqube.com/",
  },
  {
    id: "homezy",
    title: "HOMEZY",
    imageUrl: homezy,
    contents:
      "Discover Most Sutable property Our mission is to engagge in issue that are concern to individuals We are build Quality realestate Projects since 1998.",
    type: "Real Estate",
    responsibility: ["Website"],
    credit: "https://homzy-realestate.vercel.app/",
  },
  {
    id: "homeland",
    title: "HOME LAND",
    imageUrl: homeland,
    contents:
      "RentYour dream house with us",
    type: "Real Estate",
    responsibility: ["Website"],
    credit: "https://homeland-realestate.vercel.app/",
  },
  // Sales
  {
    id: "arasan",
    title: "ARASAN",
    imageUrl: Arasan,
    contents:
      "The Arasan Sales Automation application is a comprehensive tool for sales tracking and expense management, tailored to enhance the efficiency of sales operations. This application features both admin and sales domains, allowing seamless interaction between managers and sales personnel. Admins have live monitoring capabilities to track the locations visited by sales representatives in real time, providing valuable insights into their field activities. The app includes an automatic live location marking feature for sales visits, enabling accurate tracking. Additionally, sales personnel can record their attendance directly through the app and conveniently log food and travel expenses, ensuring accurate expense reporting. For added convenience, admins can download detailed location reports of sales personnel for specific dates, offering a complete overview of field operations and expenses.",
    type: "sales",
    responsibility: ["Mobile App"],
    credit: "",
  },
  {
    id: "balajigas",
    title: "BALAJI GAS",
    imageUrl: Stream,
    type: "E-Commerce",
    responsibility: ["Mobile App"],
    contents:
      "The Balaji Gas application is a streamlined solution for managing gas cylinder orders and deliveries, encompassing three main domains: Admin, Employees, and Customers. Within the Admin domain, admins can efficiently organize and assign customer orders to employees, ensuring smooth operations. Employees, in turn, can update delivery details, providing real-time status on each customer’s order. Customers have a simple, user-friendly interface where they can easily book gas cylinders, which is the core function of the application. This integrated approach simplifies the order and delivery process, ensuring a reliable and efficient service for all users.",
    credit: "",
  },
  {
    id: "smjewellers",
    title: "SM JEWELLERY",
    imageUrl: Freelance,
    type: "Jewellery",
    responsibility: ["Mobile App"],
    credit: "",
  },
  {
    id: "expense",
    title: "EXPENSIFY",
    imageUrl: expensify,
    type: "sales",
    contents:
      "Expensify is an app designed to help you track and calculate your day-to-day expenses, giving you a clear view of your savings.",
    responsibility: ["Mobile App"],
    credit: "",
  },
  // Sales
  {
    id: "pgsapp",
    title: "PGS SALES FORCE",
    imageUrl: pgsapp,
    type: "sales",
    contents:
      "PGS Sales Force is a comprehensive app designed to streamline sales operations across three main domains: Super Admin, Telecaller, and Sales. The Telecaller domain enables telecallers to reach out to customers, gather essential information, and enter these details directly into the app. The Super Admin oversees the entire process, assigning customer details to telecallers and managing workflow efficiently. The Sales domain utilizes the data provided by Telecallers to follow up with leads and close sales, ensuring a smooth, coordinated sales process from customer engagement to final sale.",
    responsibility: ["Mobile App"],
    credit: "",
  },
  // restraunt and foods
  {
    id: "Milletnoodles",
    title: "Milletnoodles",
    imageUrl: milletnoodles,
    type: "Restraunt",
    contents:
      "Fuel Your Life with millets, That Powers Wellness Discover the power of millets, nature's ancient grains that pack a punch when it comes to health and wellness. Rich in essential nutrients like fiber, protein, vitamins, and minerals, millets are the perfect choice for a balanced diet.",
    responsibility: ["Website"],
    credit: "https://milletnoodles.com/welcome-to-sri-lakshmi-foods/",
  },
  {
    id: "fooddelivery",
    title: "FOOD DELIVERY",
    imageUrl: fooddeliverypos,
    type: "Restraunt",
    contents:
      "PGS Sales Force is a comprehensive app designed to streamline sales operations across three main domains: Super Admin, Telecaller, and Sales. The Telecaller domain enables telecallers to reach out to customers, gather essential information, and enter these details directly into the app. The Super Admin oversees the entire process, assigning customer details to telecallers and managing workflow efficiently. The Sales domain utilizes the data provided by Telecallers to follow up with leads and close sales, ensuring a smooth, coordinated sales process from customer engagement to final sale.",
    responsibility: ["Mobile App"],
    credit: "",
  },
  {
    id: "coffee",
    title: "LEO Coffee",
    imageUrl: coffee,
    type: "Restraunt",
    contents:
      "We are a family run enterprise, who understand and cherish coffee.Our philosophy exemplified by our traditions to dispatch only the freshest coffee to our customers reflects our constant strive for perfection.Passionate dedication and unrelenting efforts by every member of the Leo family is what enriched our legacy.",
    responsibility: ["Website"],
    credit: "https://www.leocoffee.co.in/?srsltid=AfmBOorHiOl_nSnYL9h8UDtbZireQL-dlMT5wCHnAU8pB9JQXbtOLfZV",
  },
  {
    id: "carrental",
    title: "CAR RENTAL",
    imageUrl: carrental,
    type: "rentals",
    contents:
      "Our Car Rental app allows customers to easily book a car for rent, offering convenience and flexibility for their travel needs. With a user-friendly interface, customers can browse available vehicles, choose the one that best suits their preferences, and complete their booking directly through the app. Whether it's for a short trip or a long journey, the app ensures a smooth rental experience, with flexible pick-up and drop-off options, all designed to provide maximum comfort and convenience during your travels.",
    responsibility: ["Mobile App"],
    credit: "",
  },
  {
    id: "indusgo",
    title: "INDUS GO",
    imageUrl: indusgo,
    type: "rentals",
    contents:
      "A car subscription is a great option as it offers flexibility, convenience, and the ability to switch cars quickly with no long-term commitments and also doesn't require a large upfront payment such as insurance, road tax, and other payments which are required when buying a car.",
    responsibility: ["Website"],
    credit: "https://www.indusgo.in/subscription/",
  },
  // Portfolios
  {
    id: "jupiterdigitals",
    title: "JUPITER DIGITALS",
    imageUrl: jupiterdigitals,
    contents:
      "Jupiter Digitals is an innovative IT company specializing in delivering customized digital solutions for businesses of all sizes. Our website showcases a range of services, including web and mobile app development, digital marketing, cloud computing, and software consulting. With a team of skilled professionals, Jupiter Digitals is dedicated to transforming ideas into powerful digital experiences that drive growth and efficiency. Explore our portfolio, read client success stories, and discover how we can help elevate your business in today’s digital landscape.",
    type: "Portfolios",
    responsibility: ["Web Development"],
    credit: "https://jupiterdigitals.in/",
  },
  {
    id: "balajiwebgas",
    title: "BALAJI GAS",
    imageUrl: balajiweb,
    contents:
      "Welcome to Balaji Gas, your trusted provider of gas solutions. On this page, you'll find all the essential details about our Madurai agency, including the services we offer, our contact information, and our commitment to providing reliable and safe gas solutions for households and businesses alike. Whether you're looking for a new gas connection, refills, or any other related services, our Madurai agency is here to serve you with the best in customer service and high-quality gas products. We pride ourselves on ensuring the convenience and safety of our customers with every transaction. Explore our offerings and get in touch for all your gas needs!",
    type: "Portfolios",
    responsibility: ["Web Development"],
    credit: "https://balaji-web-da477.web.app/#Home",
  },
  {
    id: "thetamarindtree",
    title: "The Tamarind Tree",
    imageUrl: tamarindtree,
    contents:
      "Nestled in a five-acre garden of beautiful trees, including a 400-year-old tamarind tree, a pond, a colonial Bandstand, elegant pavilions, and ample open spaces, The Tamarind Tree is a place like none other. Once someone’s home, then a restaurant, sometimes a weekend getaway, it is now a luxury space for weddings, corporate expeditions, and leisure retreats. It is one of the most understated, yet magical resorts for weddings in Bangalore. With a heartfelt commitment to preserving heritage and providing unparalleled experiences, we invite you to escape to our oasis—a hidden gem in the heart of Bangalore, where every event is infused with warmth, authenticity, and the enchantment of our surroundings.",
    type: "Portfolios",
    responsibility: ["Web Development"],
    credit: "https://www.thetamarindtree.in/",
  },

  // travel
  {
    id: "TravelsInCoimbatore",
    title: "Travels In Coimbatore",
    imageUrl: cbetravel,
    contents:
      "Car Rental in Coimbatore with Driver",
    type: "rentals",
    responsibility: ["Web Development"],
    credit: "https://www.travelsincoimbatore.com/",
  },

  // Social
  {
    id: "legalrightsconsumerprotectionassociation",
    title: "Legal Rights Consumer Protection Association",
    imageUrl: legalrightsconsumerprotectionassociation,
    type: "social",
    contents:
      "Legal Rights Consumer Protection Association",
    responsibility: ["Website"],
    credit: "https://legalrightsconsumerprotectionassociation.vercel.app/",
  },

  // E-Commerce
  {
    id: "madurakadai",
    title: "MADURAI KADAI",
    imageUrl: kadai,
    type: "E-Commerce",
    contents:
      "Our Madurai Kadai app is designed to offer an engaging shopping experience with a wide range of features to make buying items convenient and enjoyable. From browsing an extensive selection of products to seamless purchasing options, our app aims to attract customers and enhance their shopping journey. The primary goal of Madurai Kadai is to provide a user-friendly platform that encourages customers to explore, discover, and purchase items effortlessly.",
    responsibility: ["Mobile Application"],
    credit: "",
  },
  {
    id: "grocergo",
    title: "GROCER GO",
    imageUrl: grocergo,
    type: "E-Commerce",
    contents:
      "Discover a diverse selection of fresh produce, pantry essentials, and more, all at affordable prices. With speedy and reliable delivery, we bring the store to your doorstep. Our commitment to freshness, secure online ordering, and community values make us your ideal grocery partner. Shop anytime, explore our culinary inspiration, and experience grocery shopping reimagined. Welcome to GrocerGo - where every purchase is a pleasure!",
    responsibility: ["Website"],
    credit: "https://grocergo-three.vercel.app/",
  },
  {
    id: "jupiterweb",
    title: "JUPITER CRACKERS",
    imageUrl: Jupiter,
    contents:
      "Welcome to Jupiter Crackers, your one-stop online destination for purchasing premium crackers at unbeatable offer prices. Our dynamic website brings you a wide range of crackers, from sparklers to high-end fireworks, all available at discounted rates. Whether you're celebrating a festival or planning a special event, we ensure you get the best deals without compromising on quality. With easy navigation, secure payment options, and fast delivery, shopping for crackers has never been more convenient. Stay updated with our exclusive offers and promotions to enjoy incredible savings on your favorite crackers. Explore our collection and make your celebrations brighter with Jupiter Crackers!",
    type: "E-Commerce",
    responsibility: ["Web Development"],
    credit: "https://jupiter-crackers-web.vercel.app/",
  },
  {
    id: "wealthwiseweb3",
    title: "WealthWise Website",
    imageUrl: wealthwiseweb3,
    contents:
      "WealthWise is a modern portfolio management solution that combines sleek design with advanced functionality. Its intuitive app and robust dashboard empower users to track their savings, investments, and financial goals effortlessly.",
    type: "AI Integration",
    responsibility: ["AI Integration", "Website"],
    credit: "",
  },
  // Videos
  {
    id: "sanitaryweb",
    title: "SANITARY WORLD MADURAI",
    imageUrl: ManagementApp,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/28Rto9JjTCA",
  },
  {
    id: "aathicycles",
    title: "Aathi Cycles",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/nbag9Kl0lqU",
  },
  {
    id: "ashvattha school",
    title: "Ashvattha School",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/5S6Shji3azs",
  },
  {
    id: "Venkateshwara Super Market",
    title: "sri Venkateshwara Super Market",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/eaXV8nrOreg",
  },
  {
    id: "MaduraKadai",
    title: "Madurai Kadai",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/wiZcQselTkU",
  },
  {
    id: "Om Muruga Sarees",
    title: "Om Muruga Sarees",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/LsSSpRfN_TY",
  },
  {
    id: "Sri Dharmasastha Jewellery",
    title: "Sri Dharmasastha Jewellery",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/XLFo1h6YxZU",
  },
  {
    id: "Nest Pre School",
    title: "Nest Pre School",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/lfScENV7OKA",
  },
  {
    id: "Vishaka Dental Care",
    title: "Vishaka Dental Care",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/HfxEgyrsLoM",
  },
  {
    id: "Ramalingam Tiles",
    title: "Ramalingam Tiles",
    imageUrl: wealthwiseweb3,
    type: "Videos & ad",
    responsibility: ["Videos & ad"],
    videourl: "https://www.youtube.com/embed/jaOTRxqhA8A",
  },
];

export const Advantages = [
  [
    {
      title: "Attention to Detail",
      description:
        "We believe that every pixel matters. Our meticulous attention to detail ensures that every design element is carefully crafted for maximum impact.",
      imageUrl: Collaborative,
    },
    {
      title: "Creativity",
      description:
        "Our designers are passionate about pushing the boundaries of creativity to deliver designs that stand out.",
      imageUrl: Communicative,
    },
  ],
  [
    {
      title: "Collaborative Process",
      description:
        "We believe in collaboration and work closely with you throughout the design process to ensure that your vision is brought to life.",
      imageUrl: Favorite,
    },
    {
      title: "User-Centric Approach",
      description:
        "We prioritize the needs and preferences of your target audience to create designs that resonate with them.",
      imageUrl: Management,
    },
  ],
];

export const TeamMembers = [
  {
    name: "Blissfullmind",
    // position: 'CEO',
    imageUrl: bissfullmind,
  },
  {
    name: "Cell Park",
    // position: 'Project Manager',
    imageUrl: ccellpark,
  },
  {
    name: "Mobile Bazar",
    // position: 'UI/UX Designer',
    imageUrl: mobilebazar,
  },
  {
    name: "Iris Fintness Studio",
    // position: 'Back-end Developer',
    imageUrl: Iris,
  },
  {
    name: "Venkadeshwara Super Market",
    // position: 'Back-end Developer',
    imageUrl: venkedeshwarasupermarket,
  },
  {
    name: "Ashvattha",
    // position: 'HRD',
    imageUrl: ashvattha,
  },

  {
    name: "Balaji Gas Agency",
    // position: 'HRD',
    imageUrl: bharathgas,
  },
  {
    name: "Madurai Kadai",
    // position: 'UI/UX Designer',
    imageUrl: MaduraiKadai,
  },
  {
    name: "Arasan",
    // position: 'UI/UX Designer',
    imageUrl: arasan,
  },

  {
    name: "Nest Pre School",
    // position: 'Mobile Developer',
    imageUrl: nestschool,
  },
  {
    name: "Kids O Kids",
    // position: 'Mobile Developer',
    imageUrl: kids,
  },
  {
    name: "Kiara",
    // position: 'Mobile Developer',
    imageUrl: Kiara,
  },
  {
    name: "Wishdom Cool Tech",
    // position: 'Mobile Developer',
    imageUrl: wishdomcooltech,
  },
  {
    name: "Hyrox Fitnexx",
    // position: 'Back-end Developer',
    imageUrl: hyrox,
  },
  {
    name: "Thai Departmental store",
    // position: 'Back-end Developer',
    imageUrl: thaidepartmentalstore,
  },

  {
    name: "Sivakasi Printing Assotiation",
    // position: 'Back-end Developer',
    imageUrl: sivakasiprinters,
  },
  {
    name: "Dharma Sastha Jewllers",
    // position: 'Front-end Developer',
    imageUrl: dharma,
  },
  {
    name: "Om Muruga",
    // position: 'Front-end Developer',
    imageUrl: ommuruga,
  },
  {
    name: "HNA Mens wear",
    // position: 'Front-end Developer',
    imageUrl: hna,
  },
  {
    name: "Visaka dental hospital",
    // position: 'Front-end Developer',
    imageUrl: vikashahospital,
  },
  {
    name: "Tirupathi Jewelleries",
    // position: 'Front-end Developer',
    imageUrl: tirupathijewelleries,
  },
  {
    name: "Ramalinga Tiles",
    // position: 'Front-end Developer',
    imageUrl: ramalingatiles,
  },
  {
    name: "SM Jewelleries",
    // position: 'CEO',
    imageUrl: smjewelleries,
  },
  {
    name: "Dreamz Beauty Care",
    // position: 'Front-end Developer',
    imageUrl: Dreamz,
  },
];
