/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import HTMLFlipBook from "react-pageflip";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Button from "elements/Button";
import { Fade } from "react-awesome-reveal";
import pgs1 from "../assets/Flipbook/PGS-0001.jpg";
import pgs2 from "../assets/Flipbook/PGS-0002.jpg";
import pgs3 from "../assets/Flipbook/PGS-0003.jpg";
import pgs4 from "../assets/Flipbook/PGS-0004.jpg";
import pgs5 from "../assets/Flipbook/PGS-0005.jpg";
import pgs6 from "../assets/Flipbook/PGS-0006.jpg";
import pgs7 from "../assets/Flipbook/PGS-0007.jpg";
import pgs8 from "../assets/Flipbook/PGS-0008.jpg";
import pgs9 from "../assets/Flipbook/PGS-0009.jpg";
import pgs10 from "../assets/Flipbook/PGS-0010.jpg";

const Page = React.forwardRef((props, ref) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className="flex items-start justify-center relative"
      ref={ref}
      style={{
        height: "100%",
        padding: "0",
        margin: "0",
      }}
    >
      <div className="text-center text-black mt-12 z-0">
        <Button
          onClick={handleFlip}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              handleFlip();
            }
          }}
          tabIndex="0"
          style={{
            background: "none",
            border: "none",
            padding: "0",
            cursor: "pointer",
          }}
        >
          <img
            src={isFlipped ? props.backsideImage : props.image}
            alt={`Page ${props.number}`}
            className="m-0 p-0"
            style={{
              maxWidth: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "opacity 0.5s ease-in-out",
            }}
          />
        </Button>
      </div>
    </div>
  );
});

Page.propTypes = {
  number: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  backsideImage: PropTypes.string.isRequired,
};

export default function MyBook() {
  const flipbookRef = useRef();

  const handlePrev = () => {
    if (flipbookRef.current) {
      flipbookRef.current.pageFlip().flipPrev();
    }
  };

  const handleNext = () => {
    if (flipbookRef.current) {
      flipbookRef.current.pageFlip().flipNext();
    }
  };

  return (
    <div>
      <Fade direction="right" triggerOnce>
        <h1 className="text-5xl text-theme-blue text-center font-bold">
          Pro Global Solutions
        </h1>
      </Fade>
      <div className="flex items-center justify-center min-h-100 px-4 py-0 relative m-0">
        <HTMLFlipBook
          ref={flipbookRef}
          width={800}
          height={700}
          size="stretch"
          minWidth={200}
          minHeight={300}
          maxWidth={1000}
          maxHeight={700}
          className=""
        >
          <Page
            number="1"
            image={pgs1}
            backsideImage={pgs1} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="2"
            image={pgs2}
            backsideImage={pgs2} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="3"
            image={pgs3}
            backsideImage={pgs3} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="4"
            image={pgs4}
            backsideImage={pgs4} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="5"
            image={pgs5}
            backsideImage={pgs5} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="6"
            image={pgs6}
            backsideImage={pgs6} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="7"
            image={pgs7}
            backsideImage={pgs7} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="8"
            image={pgs8}
            backsideImage={pgs8} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="9"
            image={pgs9}
            backsideImage={pgs9} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          <Page
            number="10"
            image={pgs10}
            backsideImage={pgs10} // Pass backside image
            style={{ backgroundColor: "white" }}
          />
          {/* Add the remaining pages here */}
        </HTMLFlipBook>

        {/* Left arrow button */}
        <Button
          onClick={handlePrev}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 md:ml-5 sm:ml-0 rounded-full shadow-lg"
          style={{ zIndex: 10 }}
        >
          <FaArrowLeft className="text-2xl" />
        </Button>
        {/* Right arrow button */}
        <Button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 md:mr-5 sm:mr-0 rounded-full shadow-lg"
          style={{ zIndex: 10 }}
        >
          <FaArrowRight className="text-2xl" />
        </Button>
      </div>
    </div>
  );
}
