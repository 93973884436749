/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import { FaSquareWhatsapp } from "react-icons/fa6";
import BrandIcon from "./BrandIcon";
import Button from "../elements/Button";

export default function Footer() {
  const iconStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "50px",
    height: "50px",
    zIndex: 1000,
    cursor: "pointer",
    padding: "0",
    borderRadius: "8px",
  };

  return (
    <div className="bg-gray-50 border-t border-gray-200 pb-6">
      <a
        href="https://wa.me/9360620595"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          ...iconStyle,
          animation: "bounce 1s infinite, glow 1.5s infinite",
          bottom: "80px",
          right: "50px",
        }}
      >
        <FaSquareWhatsapp
          style={{
            color: "#25D366",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            fontSize: "30px",
          }}
        />
      </a>

      <style>
        {`
    @keyframes bounce {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }

    @keyframes glow {
      0%, 100% {
        box-shadow: 0 0 5px #25D366, 0 0 10px #25D366, 0 0 15px #25D366;
      }
      50% {
        box-shadow: 0 0 15px #25D366, 0 0 20px #25D366, 0 0 25px #25D366;
      }
    }
  `}
      </style>

      <div className="container flex-col mx-auto ">
        <div className="flex flex-col sm:flex-row mt-8 justify-center">
          <div className="w-1/3 flex-col ml-16 mr-8">
            <BrandIcon />
            <p className="w-full text-lg mt-3 text-gray-400 font-light">
              Growing Your Business <br />
              Is Our Calling
            </p>
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Email</h1>
            <p className="text-lg text-gray-400 font-light pb-2">
              info@proglobalsolutions.in
            </p>
          </div>
          <div className="w-3/4 md:w-1/3 mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
            <h1 className="text-lg text-theme-blue">IT Office</h1>
            <p className="text-lg text-gray-400 font-light">
              F3,1/113, Jmj Complex,TNAU Nagar, Othakadai
              Madurai - 625107, Tamil Nadu.
            </p>
            <h1 className="text-lg text-theme-blue">Contact</h1>
            <a href="tel:9360620595" className="text-lg text-gray-400 font-light mb-5">
              9360620595
            </a>

            <h1 className="text-lg text-theme-blue">Sales Office 1</h1>
            <p className="text-lg text-gray-400 font-light mb-5">
              21-A SM St, Opp BSNL Office, Sivakasi, Tamil Nadu – 626123
            </p>
            <h1 className="text-lg text-theme-blue">Sales Office 2</h1>
            <p className="text-lg text-gray-400 font-light mb-5">
              21-A SM St, Opp BSNL Office, Sivakasi, Tamil Nadu – 626123
            </p>
          </div>
          <div className="w-1/3 ml-16 sm:ml-0 mt-0">
            <h1 className="text-lg text-theme-blue pt-4 pb-2">Social</h1>
            <Button
              href="https://wa.me/9360620595"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Whatsapp
            </Button>
            <Button
              href="https://www.linkedin.com/company/pgs-in/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              LinkedIn
            </Button>
            <Button
              href="https://github.com/ProGlobalSolutions"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Github
            </Button>

            <Button
              href="https://www.youtube.com/@ProGlobalSolutions"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Youtube
            </Button>
            <Button
              href="https://www.facebook.com/proglobalsolutions.in/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Facebook
            </Button>
            <Button
              href="https://x.com/pgs_in"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Twitter
            </Button>
            <Button
              href="https://www.instagram.com/proglobalsolutions.in/"
              type="link"
              target="_blank"
              className="flex text-lg text-gray-400 font-light hover:underline"
              isExternal
            >
              Instagram
            </Button>
          </div>
        </div>
        <div className="flex-col text-center mt-7">
          <p className="text-lg text-gray-400 font-light">
            Copyright 2024 - All rights reserved -&nbsp; Pro Globalsolutions
          </p>
          <div className="flex-row">
            <p className="inline-block text-lg text-gray-400 font-light">
              Made with &#x2764; by&nbsp;
            </p>
            <Button
              href="https://www.linkedin.com/company/pgs-in/"
              type="link"
              target="_blank"
              className="text-lg text-theme-purple font-light"
              isExternal
            >
              Pro Global solutions
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
